.fixed-height {
    &--4 {
        min-height: 4rem;
    }

    &--18 {
        min-height: 18rem;
    }

    &--20 {
        min-height: 20rem;
    }

    &--24 {
        min-height: 24rem;
    }
}

.margin {

    &--bottom-0-25 {
        margin-bottom: .25rem;
    }

    &--bottom-0-5 {
        margin-bottom: .5rem;
    }

    &--bottom-1 {
        margin-bottom: 1rem;
    }
    
    &--bottom-1-5 {
        margin-bottom: 1.5rem;
    }
    
    &--top-1 {
        margin-top: 1rem;
    }
    
    &--bottom-2 {
        margin-bottom: 2rem;
    }
    
    &--top-2 {
        margin-top: 2rem;
    }
    
    &--bottom-3 {
        margin-bottom: 3rem;
    }
        
    &--top-3 {
        margin-top: 3rem;
    }

    &--bottom-5 {
        margin-bottom: 5rem;
    }
        
    &--top-5 {
        margin-top: 5rem;
    }
}

.no-margin {
    margin: 0;
}

.centered {
    text-align: center;
}

.block {
    display: block;
}
.display-inline {
    display: inline;
}

.brown {
    color: $text-brown;
}

.capitalize {
    text-transform: capitalize;
}

.underline {
    border-bottom: 1px solid $text-dark-blue;
}

.massive {
    font-size: 8rem;
}

.tpw-shadow {
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 8px;
}

.dark-gray {
    color: $dark-gray;
}

.light-gray {
    color: $light-gray;
}

.ot {
    font-size: .8rem;
    color: #0e709f;
    padding: 0.45rem 1rem;
}

