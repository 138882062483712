.search-form {
    @include border-radius($global-border-radius);
    background-color: $blue-dark-bg; 
    padding: .9rem;
    position: relative;

    &__input {
        @include border-radius($global-border-radius);
        font-size: 1.5rem;
        height: 3.1rem;
        margin: 0;
        width: 100%;
    }
    
    &__button {
        font-size: 1.5rem;
        height: 3.1rem;
        padding: .7rem;
        position: absolute;
        right: .9rem;
        top: .9rem;
    }

    @media screen and (max-width: 39.9375em) {
        &__button {
            display: block;
            left: auto;
            margin: 1rem 0 0;
            position: static;
            right: auto;
            width: 100%;
        }
    }
}

#navbar-search .search-form {
    padding: 0.1rem;

    &__input {
      height: 1.9rem;
      font-size: 0.9rem;
    }

    &__button {
      height: 1rem;
      padding: 0.5rem;
      right: 1rem;
      position: absolute;
      background-color: inherit;
      max-width: 1rem;
      margin: -0.4rem;
    }
}

#navbar-search .fa-search {
  color: #e1e4e5;
  position: absolute;
  font-size: 1.3rem;
  cursor: pointer;
  vertical-align: middle;
  top: -0.1rem;
  right: -0.1rem;
}
