.hero-block {
    background-color: $blue-medium-bg;
    border-bottom: 1px solid $blue-dark-bg;
    padding: 1rem 0.5rem;
    
    &__h1 {
        font-size: 2rem;
        text-transform: capitalize;
    }
    
    &__headline {
        font-size: 1rem;
    }
    
    &--plain {
        background-color: transparent;
        border-bottom: 1px solid transparent;
    }
}
