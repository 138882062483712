// Term Paper Warehouse
// ********************

@charset "UTF-8";

/// Global width of your site. Used by the grid to determine row width.
$global-width: 66.25rem;

@import 'vendor/_all';

@import 'utilities/_all';

body {
    font-size: 16px;
}

a {
    color: $text-medium-blue;
}

@import 'components/_all';

//importing helpers last means they will override other selectors, which is mostly how you use them
@import 'utilities/_helpers';

tpw-remove-if-premium {
    display: block;
}
