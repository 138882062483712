.callout {
    
    @include border-radius($global-border-radius);
    background-color: $blue-light-bg;
    padding: 2rem;
    
    &--center {
        text-align: center;
    }
    
    &--yellow {
        border: 1px solid $border-yellow;
        background-color: $yellow;
    }
    
    &--shallow {
        padding: 1rem;
    }
    
    &-list {

        &__list {
            margin-left: 1.2rem;
        }

        &-box {
            border: 1px solid $blue-dark-bg;
            padding: 1rem 2rem 2rem;
            position: relative;
        }
        
        
        &__heading {
            color: $text-brown;
            font-size: 1.5rem;
        }
        
        &__item {            
            list-style-type: none;
            font-size: 1.1rem;

        }
        
        &__footer {
            bottom: 10px;
            position: absolute;
            right: 10px;
            font-size: 16px;
            font-weight: 500;
            color: $black;
        }
    }
    &-categories {
        background:$blue-dark-bg;color:$black;border-radius:5px;padding:5px;margin:5px;display:inline-block;font-size:.8rem
    }
}
