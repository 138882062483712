
.tpw-paper-container {
    position: relative;
    word-wrap: break-word;

    &__article {
        border-top: 1px solid;
        border-right-style: none;
        border-bottom-style: none;
        border-left-style: none;
    }

    &__gradient {
        margin-top: -300px;
        height: 500px;
        width: 100%;
        position: relative;
        background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 80%);
        background: -o-linear-gradient(rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 80%);
        background: linear-gradient(rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 80%);

        &__cta {
            bottom: 20px;
            position: absolute;
            left: 0;
            margin: auto;
            padding: 15px 0px;
            right: 0;
            width: 240px;
        }
    }

    &__title {
        border-bottom: 1px solid $dark-gray;
        font-size: 2rem;
        margin-bottom: 1.2rem;
    }

    &__is-free {
    
        
        &-tag {
            @include premium-free-tag($essay-free-copy-color);
        }
    
        &-tag-copy {
            @include premium-free-tag-copy($essay-free-copy-color);
        }
      }
    
      &__is-premium{
    
        &-title {
          padding-top: 2rem;
          width: 100%;
          @include breakpoint(medium) {
            padding-top: 0rem;
            width: 80%;
          }
          @include breakpoint(large) {
            width: 85%;
          }
        }
    
        
        &-container {
          background-image: url($essay-premium-bg-mobile);
          background-repeat: no-repeat;
          height: 2.25rem;
          left: -.75rem;
          margin: 0 0 1rem;
          padding: 0;
          position: relative;
          top: 0;
          width: 10rem;
    
          @include breakpoint(medium) {
            background-image: url($essay-premium-bg);
            border-top-right-radius: 10px;
            height: 11.5rem;
            left: unset;
            margin: 0;
            padding: 2.5rem .5rem;
            position: absolute;
            top: 0;
            right: .95rem;
            top: 0;
            width: 8.75rem;
          }
          
        }
    
        &-copy {
          color: $essay-premium-copy-color;
          display: block;
          font-size: 1rem;
          line-height: 2rem;
          padding: 0 0 0 1rem;
          text-align: left;
          z-index: 1;
    
          @include breakpoint(medium) {
            font-size: 1.5rem;
            padding: 0;
            text-align: center;
          }
        }
    
        &-tag {
            @include premium-free-tag($essay-premium-copy-color);
        }
    
        &-tag-copy {
            @include premium-free-tag-copy($essay-premium-copy-color);
        }
    
      }
}
