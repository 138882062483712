$white: #fff;
$black: #000;
$dark-gray: #666;
$light-gray: #888;
$yellow: #FFFFD9;

$blue-dark-bg: #A3D2F3;
$blue-medium-bg: #DCEEFA;
$blue-light-bg: #F7FBFF;

$text-dark-blue: #00344D;
$text-medium-blue: #0e709f;
$text-brown: #994800;

$tpw-green-moss: #749f0f;
$tpw-green-moss-light: #e3eccf;

$border-yellow: #f7e699;

$button-gradient-top: #75AA3B;
$button-gradient-bottom: #AFCF87;

