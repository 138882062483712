.button {
    &--join-now {
        @include border-radius($button-border-radius);
        margin: 0;
        padding: 1rem 2rem;
        
        &__heading{
            font-size: 1.1rem;
            text-transform: uppercase;
        }
    }

    &__icon {
        height: 24px;
        border-width: 1px;
        border-style: solid;
        padding: 6px 2px;
        margin-top: -7px;
        margin-left: 10px;
        border-radius: 1em;
    }
}
