.homepage {
    padding-top: 4em;
}
.display-none{
    display:none;
}
.display-block{
    display:block;
}
.card-list{
    background:$blue-light-bg;
    margin-bottom: 1rem;
    max-height: 200px;
    min-height: 200px;
    word-break:break-word;
    border:1px solid $blue-dark-bg;
    position:relative;
    padding: 1.25rem 1.5rem;
}
.card-heading {
    color: #000;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Open Sans',sans-serif;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.card-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #000;
    font-size: 14px;
    line-height: 22px;
    font-family: 'Open Sans',sans-serif;
    font-weight: 100;
}
.card-text::after{
    content:'...'
}
.card-footer{
    font-size:14px;
    position:absolute;
    bottom: 10px;
    right:10px;
    color:$black;
}
.align-center{
    text-align:center
}
.list-style{
    font-size: .875rem;
    list-style-type:disc;
    margin: 0 0 0.4rem;
    color: $text-medium-blue;
}
.trim-data{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.position-relative{
    position: relative;
}
