.tpw-main-container {
    padding-top: 2rem;
}

.tpw-categories {
    padding-top: 1rem;
}

.section-heading {
    border-bottom: 1px solid $dark-gray;
    padding-bottom: .5rem;
    
    &--border-top {
        border-top: 1px solid $dark-gray;
        padding-top: 0.5rem;
    }
}

// Force add down to line up with search box
.browse-page {
    .hero-block {
        .medium-4 {
            img {
                margin-top: 2rem;
            }
        }
    }
}

.privacy-policy,
.terms-of-service {
    font-size: .9rem;
}

// Custom html
account-header {
    display: block;
}

// For essay good click format

.pb-wrap {
  min-height: 10rem;
  min-width: 10rem;
  width: 100%;
  position: relative;
  z-index: 20;
  bottom: -22rem;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  background-color: white;
}

.pb-wrap p {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pb-bar {
  height: 2rem;
  width: 80%;
  background-color: #f1f1f1; /* grey */
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
}

.pb-loader {
  height: 1.9rem;
  width: 0%;
  background-color: #4CAF50; /* green */
}

.t-pw article {
  font-size: 1rem;
  line-height: 1.7rem;
}

@media screen and (min-width: 40em) { /* medium */
 
  .t-pw {
   border: 1px solid #EEE;
   border-radius: 10px;
   padding: 4rem;
  }
  
  .t-pw article {
   font-size: 1.125rem;
   line-height: 2rem;
  }
  
}

@media screen and (min-width: 64em) { /* large */
  
  .t-pw {
    padding: 6rem;
  }
  
  .t-pw article {
    font-size: 1.25rem;
    line-height: 2.2rem;
  }
  
}