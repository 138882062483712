.logo {
    margin-bottom: .3rem;
    text-align: center;
    
    &__heading {
        color: $dark-gray;
        font-size: .8rem;
    }
}

.nav-bar {
    border-top: 1px solid $text-medium-blue;
    border-bottom: 1px solid $text-medium-blue;
    background-color: $blue-dark-bg;
    padding: .4rem;

    .menu a {
        font-weight: 500;
    }
}

.nav-container {
	margin-left: auto;
}
.search-mobile-show {
	display: none;
}
.toggle {
	display: none;
}
[id^=drop] {
	display: none !important;
}
nav {
	justify-content: end;
	&:after {
		content: "";
		display: table;
	}
}
.header-navbar {
	nav {
		ul {
			float: left;
			list-style: none;
			li {
				margin: 0;
				display: inline-block;
				&:hover {
					>ul {
						display: inherit;
					}
				}
			}
			ul {
				display: none;
				position: absolute;
				z-index: 4444;
				background-color: #fff;
				li {
					float: none;
					display: list-item;
					background: #fff;
					&:hover {
						background: #555;
						>a {
							color: #fff;;
						}
						a {
							&::after {
								border-color: #fff;
							}
						}
					}
					a {
						&:not(:only-child) {
							&:after {
								right: 8px;
								border-width: 2px 2px 0 0;
							}
						}
					}
					input {
						&:hover {
							color: #fff;;
						}
					}
				}
				ul {
					left: 270px;
					top: 0;
				}
			}
		}
		a {
			display: block;
			padding: 0 16px;
			color: #0e709f;
			font-size: 16px;
			line-height: 50px;
			text-decoration: none;
		}
		li {
			a {
				&:not(:only-child) {
					&:after {
						border: solid #0e709f;
						border-width: 0 2px 2px 0;
						display: inline-block;
						padding: 3px;
						top: 45%;
						transform: rotate(45deg) translateY(-50%);
						-webkit-transform: rotate(45deg) translateX(-50%);
						content: "";
						margin-left: 10px;
					}
				}
			}
		}
	}
	visibility: visible !important;
}
.nav-toggle {
	span {
		cursor: pointer;
		border-radius: 1px;
		height: 3px;
		width: 25px;
		background: #000;
		position: absolute;
		display: block;
		content: "";
		transition: .3s ease-in-out;
		&:after {
			cursor: pointer;
			border-radius: 1px;
			height: 3px;
			width: 25px;
			background: #000;
			position: absolute;
			display: block;
			content: "";
			transition: .3s ease-in-out;
			bottom: -10px;
		}
		&:before {
			cursor: pointer;
			border-radius: 1px;
			height: 3px;
			width: 25px;
			background: #000;
			position: absolute;
			display: block;
			content: "";
			transition: .3s ease-in-out;
			top: -10px;
		}
	}
	display: none;
	left: 0;
	cursor: pointer;
	padding: 10px 35px 16px 0;
	top: 2px;
}
.nav-mobile {
	position: relative !important;
	height: auto;
	padding-right: 15px;
}

