.pagination {
    margin: 0;

    .is-disabled {
        a {
            color: #cacaca;
            cursor: not-allowed;
        }
    }
}
