.results {
    &__item {
        margin-bottom: 2rem;
        position: relative;
        padding-left: 1.5rem; 
        
        &__icon {
            left: 0;
            position: absolute;
            top: .3rem;
        } 
        
        &__title {
            font-size: 1.1rem;
            margin: 0 0 0 .5rem;
        }
        
        &__text {
            font-size: .9rem;
            margin: 0;
        }
        
        &__details {
            color: $light-gray;
            font-size: .9rem;
        }
    }
}
