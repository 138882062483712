.browse-essays {
    &__heading {
        border-bottom: 1px solid $text-dark-blue;
        color: $text-dark-blue;
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }
    
    &__description {
        font-size: 1rem;
    }
}

.category-item {
    margin-bottom: 1.5rem;
    
    &__heading {
        color: $text-brown;
        font-size: 1.2rem;
    }
    
    &__subcategories {
        color: $text-medium-blue;
        font-size: .8rem;

        &::after {
            content: ','
        }
    }
}