.faqs {
    &__item {
        margin-bottom: 1.2rem;
        
        &__heading {
            color: $text-medium-blue;
            margin-bottom: 0;
        }
        
        &__answer {
            font-size: .9rem;
        }
    }
}
