.box.box-bordered {
    border: 1px solid $blue-dark-bg;
    padding: 15px;
    min-height: 250px;
}

.box.box-bordered .category-item__subcategories::after {
    display: none;
}

.box.box-bordered .category-item__subcategories {
    background: $blue-dark-bg;
    padding: 5px;
    margin: 5px;
    display: inline-block;
    border-radius: 5px;
    color: $black;

}

.box.box-bordered {
    border: 1px solid $blue-dark-bg;
    padding: 15px;
    min-height: 250px;
}

.testimonial-box {
    text-align: center;
    border: 1px solid #ddd;
    background-color: $white;

}

.testimonial-section {
    padding-bottom: 100px !important;
}

.testimonial-box .testimonial-img {
    box-shadow: rgba(149, 157, 165, 20%) 0px 8px 24px;
    display: inline-block;
    border-radius: 50%;
    padding: 2px;
    margin-top: 10px;
}

.testimonial-box .testimonial-img img {
    margin: 0 auto;
}

.testimonial-box p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;
    font-style: italic;
    margin-bottom: 10px;
    padding-top: 20px;
    line-height: normal;
    font-size: 15px;
}

.testimonial-box p::before {
    content: url(../../images/testimonials/Quote-left.svg);
    position: relative;
    bottom: 10px;
}

.testimonial-box p::after {
    content: url(../../images/testimonials/Quote-right.svg);
    position: relative;
    top: 10px;
}

.testimonial-box h5 {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
}
.slick-dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    // bottom: -50px;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 2px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:before {
    position: absolute;
    top: 10px;
    left: 0;
    width: 15px;
    height: 15px;
    content: "";
    text-align: center;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $blue-dark-bg;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    background-color: #7DACCD;
}
.slick-dots li.slick-inactive button:before {
    opacity: 1;
    background-color: $white;
}