.footer {
    background-color: $blue-light-bg;
    border-top: 1px solid $blue-medium-bg;
    padding: 1rem;
    padding-bottom: 10rem;
    text-align: center;
    width: 100%;
    
    &__copyright {
        color: $dark-gray;
        font-size: .8rem;
        margin: 0;
    }
    
    &__bottom-menu {
        &__item {
            a {
                font-size: .8rem;
            }
        }
    }
}
