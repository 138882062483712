.essay-dir {
    &__heading {
        color: #00344d;
        font-size: 1.3rem;
        margin-bottom: 1.4rem;
    }

    &__list {
      line-height: 1.5rem;
    }

    &__item {
        font-size: .8rem;
        margin-bottom: 0.2rem;
    }

    &__link {
      color: $text-medium-blue;
      margin:0 0 0 0.2rem;
    }

    &__button {
      border: 1px solid $text-medium-blue;
      color: $text-medium-blue;
      padding: 1rem;
      margin-bottom: 1rem;
      position: relative;
      right: -45%;
      cursor:pointer;
    }
    
    &__cursor-pointer {
    cursor: pointer;
    }
    
    &__display-inline {
    display:inline;
    }
    
    &__display-none {
    display:none;
    }
    
    &__columns-large-2 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 1em;
    -webkit-column-gap: 1em;
    column-gap: 1em;
    }
    
    &__row {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
   }
}
